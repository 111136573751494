
<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>
      
<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaKilometrosRecorridos",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "Kilometros Recorridos",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "KILOMETROS RECORRIDOS 2021",
          align: "center",
        },

        series: [
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {  
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/getKilometrosRecorridos", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaKilometrosRecorridos(me.respuesta);
        });
    },

    async graficaKilometrosRecorridos() {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      let kmRecorridos = [];
      let mes = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        if (this.respuesta[i].mes != 0 && this.respuesta[i].mes != null) {
          mes.push(meses[this.respuesta[i].mes - 1]);
          kmRecorridos.push(parseFloat(this.respuesta[i].sumaKm));
        }
      }
      this.chartOptions.xAxis.categories = mes;
      this.chartOptions.series[0].data = kmRecorridos;
      this.chartOptions.series[0].name = "Kms Recorridos";
    },
  },

  mounted() {
    this.getGrafica();
  },
};
</script>
