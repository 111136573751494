
<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>
      
<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Highcharts from "highcharts";
export default {
  name: "GraficaTransportadoPlanificado",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          backGroundColor: "#ff1c79",
          type: "area",
        },

        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        plotOptions: {
          area: {
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        xAxis: {
          title: {
            text: "Meses",
          },
          categories: [],
        },

        yAxis: [
          {
            min: 0,
            max: 30000,
            title: {
              text: "Cantidad Barriles Diarios",
            },
            labels: {
              format: "{value}Bls",
              style: {
                fontSize: "10px",
              },
              x: -3,
            },
            plotLines: [
              {
                value: 0,
                color: "#ffffff",
                width: 1,
                zIndex: 10,
              },
            ],
          },
          {
            min: 70,
            max: 120,
            title: {
              text: "% Cumplimiento",
            },
            opposite: true,
            plotLines: [
              {
                value: 0,
                color: "#BBBBBB",
                width: 1,
                zIndex: 10,
              },
            ],
            labels: {
              format: "{value}%",
              style: {
                fontSize: "10px",
              },
              x: -3,
            },
          },
        ],

        legend: {
          layout: "vertical",
          align: "right",
          x: -50,
          verticalAlign: "top",
          y: 20,
          floating: true,
        },

        exporting: {
          enabled: true,
        },

        title: {
          text: "Transportado y Planificado Cumplimiento",
          align: "center",
          size: 5,
        },

        series: [
          {
            name: "Vol.Planificado HC",
            data: [],
            tooltip: {
              valueSuffix: " Bls",
            },
            yAxis: 0,
            color: {
              fillOpacity: 0.08,
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#c84778"],
                [1, "#DB843D"],
              ],
            },
          },
          {
            name: "Vol.Transportado",
            data: [],
            tooltip: {
              valueSuffix: " Bls",
            },
            yAxis: 0,
            color: {
              fillOpacity: 0.08,
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#ba4477"],
                [1, "#4b3074"],
              ],
            },
          },
          {
            name: "Cumplimiento",
            data: [],
            tooltip: {
              valueSuffix: " %",
            },
            yAxis: 1,
            color: {
              fillOpacity: 0.08,
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/getVolumenesPlanificado", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTransportadoPlani(me.respuesta);
        });
    },

    async graficaTransportadoPlani(resp) {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      let dias = [
        "31",
        "28",
        "31",
        "30",
        "31",
        "30",
        "31",
        "31",
        "30",
        "31",
        "30",
        "31",
      ];

      let nsvCarguePlanificado = [];
      let nsvCargueTotal = [];
      let mes = [];
      let cumplimiento = [];

      for (let i = 0; i < resp.volumenesPlanificados.length; i++) {
        if (resp.volumenesPlanificados[i].mes != 0) {
          mes.push(meses[resp.volumenesPlanificados[i].mes - 1]);
          nsvCarguePlanificado.push(
            parseFloat(
              resp.volumenesPlanificados[i].cargueNsv /
                dias[resp.volumenesPlanificados[i].mes - 1]
            )
          );
          nsvCargueTotal.push(
            parseFloat(
              resp.volumenesTotales[i].cargueNsv /
                dias[resp.volumenesPlanificados[i].mes - 1]
            )
          );
        }
      }

      for (let i = 0; i < nsvCarguePlanificado.length; i++) {
        cumplimiento.push(
          parseFloat((nsvCargueTotal[i] / nsvCarguePlanificado[i]) * 100)
        );
      }

      this.chartOptions.xAxis.categories = mes;
      this.chartOptions.series[0].data = nsvCarguePlanificado;
      this.chartOptions.series[1].data = nsvCargueTotal;
      this.chartOptions.series[2].data = cumplimiento;
    },
  },
  mounted() {
    this.getGrafica();
  },
};
</script>
