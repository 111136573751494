<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-2">
                <h5 class="mb-0">Dashboard</h5>
              </div>
              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  v-model="filter.año"
                  @change="getIndex()"
                >
                  <option
                    v-for="año in listasForms.años"
                    :key="año.numeracion"
                    :value="año.descripcion"
                    >{{ año.descripcion }}</option
                  >
                </select>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Dashboard</li>
                  <li class="breadcrumb-item active">
                    Grafica Transportado Vs Planificado
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card card-dark">
              <div class="card-header bg-frontera">
                <h5 class="card-title text-white">
                  <i class="fas fa-filter"></i> <b> Filtros Graficas</b>
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fas fa-minus"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn btn-tool"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <label for="fecha_ini">Fecha Inicio</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_inicio"
                      style="font-size: 10px; background-color: #fff"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="fecha_fin">Fecha Final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_fin"
                      style="font-size: 10px; background-color: #fff"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card card-chart">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-truck text-white"></i>
                      <b>Transportado y Planificado Cumplimiento</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaTransportadoPlanificado
                      ref="GraficaTransportadoPlanificado"
                    />
                  </div>
                  <div class="card-footer"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card card-chart">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-route text-white"></i>
                      <b>Kilometros Recorridos 2021</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaKilometrosRecorridos
                      ref="GraficaKilometrosRecorridos"
                    />
                  </div>
                  <div class="card-footer"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card card-chart">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-globe-americas text-white"></i>
                      <b>Vueltas al mundo 2021</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaVueltasMundo ref="GraficaVueltasMundo" />
                  </div>
                  <div class="card-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GraficaTransportadoPlanificado from "../dashboard/GraficaTransportadoPlanificado";
import GraficaKilometrosRecorridos from "../dashboard/GraficaKilometrosRecorridos";
import GraficaVueltasMundo from "../dashboard/GraficaVueltasMundo";

export default {
  name: "GraficaGeneralTransportado",
  components: {
    GraficaTransportadoPlanificado,
    GraficaKilometrosRecorridos,
    GraficaVueltasMundo,
  },
  data() {
    return {
      filter: {
        fecha_inicio: null,
        fecha_fin: null,
        año: "2021",
      },
      listasForms: { años: [] },
    };
  },
  methods: {
    getIndex() {
      this.$refs.GraficaTransportadoPlanificado.getGrafica();
      this.$refs.GraficaKilometrosRecorridos.getGrafica();
      this.$refs.GraficaVueltasMundo.getGrafica();
    },

    getAños() {
      axios.get("/api/lista/114").then((response) => {
        this.listasForms.años = response.data;
      });
    },
  },
  mounted() {
    this.getAños();
  },
};
</script>
